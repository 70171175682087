export const actionTypes = {
  FetchTransactions: '[FetchTransactions] Action',
};

const initialAuthState = {
  transactions: undefined,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.FetchTransactions: {
      const { transactions } = action.payload;
      return {
        ...state,
        transactions,
      };
    }

    case actionTypes.Logout: {
      return initialAuthState;
    }

    default:
      return state;
  }
};

export const actions = {
  fetchTransactions: (transactions) => ({
    type: actionTypes.FetchTransactions,
    payload: { transactions },
  }),
};

export function* saga() {}
