import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/admin/blocks`;

const fetchTransactions = async (blockId, page, limit) => {
  const res = await axios.get(
    `${FETCH_TRANSACTIONS}/${blockId}/transactions?page=${page}&limit=${limit}`
  );
  return res;
};

export { fetchTransactions };
