import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import * as transactions from '../Transactions/_redux/transactionsReducer';

import { fetchTransactions } from './_redux/transactionsAction';

import { transactionsHeaders } from '../../common/tableHeades';
import NoAvailableBlock from '../Blocks/components/NoBlocks';
import ErrorMessage from '../Common/ErrorMessage';
import moment from 'moment/moment';
import { currencyHelper } from '../../helpers/currencyHelper';
import NumberOfRows from '../Common/NumberOfRaws';

const Blocks = (props) => {
  const { blockId } = useParams();
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [transactionsTotalPage, setTransactionsTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsTotalRecord, setTransactionsTotalRecord] = useState(0);
  const [transactionsPerPage, setTransactionsPerPage] = useState(10);

  const [loading, setLoading] = useState(false);

  const { transactions } = useSelector((state) => state?.transactions);
  const getTransactions = async () => {
    setAlert(false);
    setLoading(true);
    try {
      const { data } = await fetchTransactions(
        blockId,
        currentPage,
        transactionsPerPage
      );
      setTransactionsTotalRecord(data.meta.totalItems);
      setTransactionsTotalPage(data.meta.totalPages);
      props.fetchTransactions(data?.items);
      setLoading(false);
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const changePage = async (event, value) => {
    const res = await fetchTransactions(blockId, value, transactionsPerPage);
    setCurrentPage(value);
    if (res.status === 200) {
      setTransactionsTotalRecord(res.data.totalItems);
      props.fetchTransactions(res.data.data);
    }
  };

  const changeLimit = async (event) => {
    const { value: selectedValue } = event.target;
    let res = await fetchTransactions(blockId, 1, selectedValue);
    setTransactionsPerPage(Number(selectedValue));
    setCurrentPage(1);
    if (res.status === 200) {
      setTransactionsTotalRecord(res.data.totalItems);
      setTransactionsTotalPage(res.data.totalPages);
      props.fetchTransactions(res.data.data);
    }
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {alertOn && <ErrorMessage alert={alert} />}
      {transactions && !transactions.length ? (
        <div className="card card-custom card-gutter-b">
          <NoAvailableBlock message="No transaction made so far" />
        </div>
      ) : (
        <div className="card card-custom card-gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h2 className="font-weight-bold " style={{ fontSize: '18px' }}>
                Transactions
              </h2>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {transactionsHeaders.map((header) => (
                      <th
                        key={header.columnName}
                        style={{ color: '#0071CE', minWidth: header.width }}
                        scope="col"
                      >
                        {header.columnName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {transactions && transactions?.length
                    ? transactions?.map((transaction) => (
                        <tr key={transaction?.id}>
                          <td className="td-font-size-14">{transaction?.id}</td>
                          <td className="td-font-size-14">
                            {transaction?.transactionType}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.operationType}
                          </td>
                          <td className="td-font-size-14">
                            {moment(transaction?.createdAt).format(
                              'YYYY-MM-DD'
                            )}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.user?.identifier}
                          </td>
                          <td className="td-font-size-14">{`${transaction?.planSubscription?.user?.firstName} ${transaction?.planSubscription?.user?.lastName}`}</td>
                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.user?.email}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.user?.kycStatus}
                          </td>
                          <td className="td-font-size-14">
                            {currencyHelper(
                              transaction?.originalAmount,
                              transaction?.originalCurrency
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <div
              style={{ borderBottom: '0.6px solid #ebedf3' }}
              className="divider"
            />
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {transactionsTotalPage && transactionsTotalPage > 0 ? (
                <Pagination
                  page={currentPage}
                  count={transactionsTotalPage}
                  onChange={changePage}
                />
              ) : (
                ''
              )}
              {transactionsTotalRecord && transactionsTotalRecord > 1 ? (
                <NumberOfRows
                  handleChangeLimit={changeLimit}
                  totalRecord={transactionsTotalRecord}
                  name="pageLimit"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(connect(null, { ...transactions.actions })(Blocks));
