import * as auth from '../app/modules/Auth/_redux/authRedux';
import * as blocks from '../app/modules/Blocks/_redux/blocksReducer';
import * as transactions from '../app/modules/Transactions/_redux/transactionsReducer';

import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  blocks: blocks.reducer,
  transactions: transactions.reducer,
});

export function* rootSaga() {
  yield all([]);
}
