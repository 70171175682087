import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute, LayoutSplashScreen } from '../_metronic/layout';
import Blocks from './modules/Blocks/pages';
import { Dashboard } from './modules/Dashboard';
import Transactions from './modules/Transactions';
import { BuilderPage } from './pages/BuilderPage';

const BasePage = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <ContentRoute path="/home" component={Dashboard} />
        <ContentRoute exact path="/blocks" component={Blocks} />
        <ContentRoute
          exact
          path="/blocks/:blockId/transactions"
          component={Transactions}
        />
        <ContentRoute path="/builder" component={BuilderPage} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
};
export default BasePage;
