import '../../Common/ExternalLeft/index.scss';

import React from 'react';

const LoginLeftContent = () => {
  return (
    <p className="content-style mr-10">
      Welcome back to <br /> ndovu partner admin
    </p>
  );
};

export default LoginLeftContent;
