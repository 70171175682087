import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_BLOCKS = `${REACT_APP_BASE_URL}/v1/admin/blocks/partner-blocks`;

const fetchBlocks = async (page, limit, parterId) => {
  const res = await axios.get(
    `${FETCH_BLOCKS}/${parterId}?page=${page}&limit=${limit}`
  );
  return res;
};

export { fetchBlocks };
