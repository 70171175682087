import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";

import AppFooter from "../../../../app/utils/appFooter";

import "./index.scss";

export function Footer() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer py-4 d-flex flex-lg-column footer-container  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex mt-4 flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <AppFooter internal />
        </div>
      </div>
    </div>
  );
}
