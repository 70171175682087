/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { checkToken } from './helpers/auth';
import { AuthPage, Logout } from './modules/Auth';
import { Layout } from '../_metronic/layout';
import ErrorsPage from './modules/Errors/ErrorsPage';
import BasePage from './BasePage';

export function Routes() {
  useSelector(({ auth }) => {
    return {
      isAuthorized: auth?.userDetails?.accessToken != null,
    };
  }, shallowEqual);

  const authorized = checkToken();

  return (
    <Switch>
      {!authorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}
      {
        <>
          <Layout>
            <BasePage />
          </Layout>
        </>
      }
    </Switch>
  );
}
