import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import Pagination from '@material-ui/lab/Pagination';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import * as blocks from '../../Blocks/_redux/blocksReducer';

import { blocksHeaders } from '../../../common/tableHeades';
import { fetchBlocks } from '../_redux/blocksActions';
import ErrorMessage from '../../Common/ErrorMessage';
import NoAvailableBlock from '../components/NoBlocks';
import moment from 'moment';
import { currencyHelper } from '../../../helpers/currencyHelper';

import './index.scss';
import NumberOfRows from '../../Common/NumberOfRaws';
import PopoverComponent from '../../../common/Popover';
import DepositAndWithdrawAmount from '../components/DepositWithdrawAmount';

const Blocks = (props) => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [blocksTotalPages, setBlocksTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [blocksTotalRecord, setBlocksTotalRecord] = useState(0);
  const [blocksPerPage, setBlocksPerPage] = useState(10);

  const [loading, setLoading] = useState(false);

  const { blocks } = useSelector((state) => state?.blocks);
  const getBlocks = async () => {
    setAlert(false);
    setLoading(true);
    try {
      const parterId = localStorage.getItem('partnerId');
      const { data } = await fetchBlocks(currentPage, blocksPerPage, parterId);
      props.fetchBlocks(data?.items);
      setBlocksTotalRecord(data?.meta?.totalItems);
      setBlocksTotalPages(data?.meta?.totalPages);
      setLoading(false);
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlocks();
  }, []);

  const changePage = async (event, value) => {
    const res = await fetchBlocks(value, blocksPerPage);
    setCurrentPage(value);
    if (res.status === 200) {
      setBlocksTotalRecord(res.data.totalItems);
      props.fetchBlocks(res.data.data);
    }
  };

  const changeLimit = async (event) => {
    const { value: selectedValue } = event.target;
    let res = await fetchBlocks(1, selectedValue);
    setBlocksPerPage(Number(selectedValue));
    setCurrentPage(1);
    if (res.status === 200) {
      setBlocksTotalRecord(res.data.totalItems);
      setBlocksTotalPages(res.data.totalPages);
      props.fetchBlocks(res.data.data);
    }
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {alertOn && <ErrorMessage alert={alert} />}
      {blocks && !blocks.length ? (
        <div className="card card-custom card-gutter-b">
          <NoAvailableBlock message="No block created so far." />
        </div>
      ) : (
        <div className="card card-custom card-gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h2 className="font-weight-bold " style={{ fontSize: '18px' }}>
                Blocks
              </h2>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {blocksHeaders.map((header) => (
                      <th
                        key={header.columnName}
                        style={{ color: '#0071CE', minWidth: header.width }}
                        scope="col"
                      >
                        {header.columnName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {blocks && blocks?.length
                    ? blocks?.map((block) => (
                        <tr key={block?.id}>
                          <td className="td-font-size-14">{block?.id}</td>
                          <td className="td-font-size-14">
                            {moment(block?.createdAt).format('YYYY-MM-DD')}
                          </td>
                          <td className="td-font-size-14">
                            {block?.totalTransactions}
                          </td>
                          <td className="td-font-size-14">{block.status}</td>
                          <td className="td-font-size-14">
                            {block?.deposit?.length ? (
                              <PopoverComponent
                                popoverContent={
                                  <DepositAndWithdrawAmount
                                    amounts={block?.deposit}
                                  />
                                }
                              >
                                <span
                                  style={{
                                    color: '#0071CE',
                                  }}
                                  className="font-weight-bold cursor-pointer"
                                >
                                  View
                                </span>
                              </PopoverComponent>
                            ) : (
                              <span>
                                <i
                                  style={{
                                    color: '#0071CE',
                                  }}
                                  className="ki ki-bold-more-hor"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </td>
                          <td className="td-font-size-14">
                            {block?.withdraw?.length ? (
                              <PopoverComponent
                                popoverContent={
                                  <DepositAndWithdrawAmount
                                    amounts={block?.withdraw}
                                  />
                                }
                              >
                                <span
                                  style={{
                                    color: '#0071CE',
                                  }}
                                  className="font-weight-bold cursor-pointer"
                                >
                                  View
                                </span>
                              </PopoverComponent>
                            ) : (
                              <span>
                                <i
                                  style={{
                                    color: '#0071CE',
                                  }}
                                  className="ki ki-bold-more-hor"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </td>
                          <td className="td-font-size-14">
                            <a href={`/blocks/${block?.id}/transactions`}>
                              <span className="view-transactions-button">
                                View
                              </span>
                            </a>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
              <div
                style={{ borderBottom: '0.6px solid #ebedf3' }}
                className="divider"
              />
              <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                {blocksTotalPages && blocksTotalPages > 0 ? (
                  <Pagination
                    page={currentPage}
                    count={blocksTotalPages}
                    onChange={changePage}
                  />
                ) : (
                  ''
                )}
                {blocksTotalRecord && blocksTotalRecord > 1 ? (
                  <NumberOfRows
                    handleChangeLimit={changeLimit}
                    totalRecord={blocksTotalRecord}
                    name="pageLimit"
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(connect(null, { ...blocks.actions })(Blocks));
