export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  TokenValid: '[TokenValid] Action',
};

const initialAuthState = {
  user: undefined,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const { userDetails } = action.payload;
      localStorage.setItem('partnerAdminToken', userDetails.accessToken);
      localStorage.setItem('adminDetails', JSON.stringify(userDetails.user));
      return {
        userDetails,
      };
    }

    case actionTypes.Logout: {
      return initialAuthState;
    }

    default:
      return state;
  }
};

export const actions = {
  login: (userDetails) => ({
    type: actionTypes.Login,
    payload: { userDetails },
  }),
  logout: () => ({ type: actionTypes.Logout }),
};

export function* saga() {}
