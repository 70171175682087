export const currencyHelper = (amount, currency, mathSign = null) => {
  const twoDecimalDigit = amount?.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });

  const money = currency
    ? `${twoDecimalDigit} ${currency?.toUpperCase()}`
    : twoDecimalDigit;

  return mathSign ? `${mathSign} ${money}` : money;
};
