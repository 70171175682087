import React from 'react';
import { getAdminDetails } from '../../../helpers/getAdminDetails';

const Dashboard = () => {
  const adminData = getAdminDetails();
  const { names } = adminData;

  return (
    <>
      <div
        className="px-3 py-1 my-5 card card-custom gutter-b dashboard-top-header"
        style={{ height: 'auto' }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="dashboard-name-wrapper">
            <span style={{ fontSize: '27px' }}>{names},</span>
            <br />
            <p style={{ color: '#0071CE' }} className="font-weight-bold">
              Hello and Welcome Back
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
