import dashboardIcon from '../../../../../app/icons/LeftMenuIcons/dashboard.svg';
import dashboardWhiteIcon from '../../../../../app/icons/LeftMenuIcons/dashboardWhiteIcon.svg';
import newInvestmentIcon from '../../../../../app/icons/LeftMenuIcons/newInvestments.svg';
import newInvestmentWhiteIcon from '../../../../../app/icons/LeftMenuIcons/investmentWhiteIcon.svg';

const menuList = [
  {
    title: 'Home',
    name: 'home',
    path: '/home',
    icon: { white: dashboardWhiteIcon, orange: dashboardIcon },
    active: false,
    hover: false,
  },
  {
    title: 'Blocks',
    name: 'blocks',
    path: '/blocks',
    icon: { white: newInvestmentWhiteIcon, orange: newInvestmentIcon },
    active: false,
    hover: false,
  },
];

export default menuList;
