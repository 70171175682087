import React from 'react';

import './input.scss';

export default function Input({ placeholder, name, type, formik, className }) {
  return (
    <div>
      <input
        placeholder={placeholder}
        type={type}
        className={className}
        name={name}
        {...formik?.getFieldProps(`${name}`)}
        autoComplete="off"
      />
    </div>
  );
}
