import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';

import ndovuLogo from '../../../../app/icons/ndovuIconMobile.svg';
import leftMenuIcon from '../../../../app/icons/leftMenuIcon.svg';
import rightMenuIcon from '../../../../app/icons/rightMenuIcon.svg';

import './index.scss';

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, 'header.menu.self.display') === true,
      headerMobileCssClasses: uiService.getClasses('header_mobile', true),
      headerMobileAttributes: uiService.getAttributes('header_mobile'),
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center mobile-header-container ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Logo*/}
        <Link to="/">
          <img className="ndovu-logo-image" alt="logo" src={ndovuLogo} />
        </Link>
        {/*end::Logo*/}

        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              {/* <button id="kt_aside_mobile_toggle"> */}
              <span className="mr-5" id="kt_aside_mobile_toggle">
                <img src={leftMenuIcon} alt="left menu icon" />
              </span>
              {/* </button> */}
              {/*end::Aside Mobile Toggle*/}
            </>
          )}
          {/*begin::Topbar Mobile Toggle*/}
          <span id="kt_quick_user_toggle2">
            <img
              className="mobile-profile-avatar"
              src={rightMenuIcon}
              alt="right menut icon"
            />
          </span>{' '}
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
