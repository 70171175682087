export const actionTypes = {
  FetchBlocks: '[FetchBlocks] Action',
};

const initialAuthState = {
  blocks: undefined,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.FetchBlocks: {
      const { blocks } = action.payload;
      return {
        ...state,
        blocks,
      };
    }

    case actionTypes.Logout: {
      return initialAuthState;
    }

    default:
      return state;
  }
};

export const actions = {
  fetchBlocks: (blocks) => ({
    type: actionTypes.FetchBlocks,
    payload: { blocks },
  }),
};

export function* saga() {}
