/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import './offcanvas/index.scss';

import React, { useMemo } from 'react';

import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { getAdminDetails } from '../../../../app/helpers/getAdminDetails';

export function QuickUserToggler() {
  const admin = getAdminDetails();
  const { names } = admin;
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas',
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 first-name"
            id="kt_quick_user_toggle"
          >
            <>
              <span className="d-none d-md-inline mr-5">{names}</span>
            </>
          </div>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
