import React from 'react';

const ErrorMessage = ({ alert, alertRef }) => {
  return (
    <div
      className={`mx-5 mt-5 fade show`}
      role="alert"
      style={{
        color: alert.alertMessageType === 'success' ? '#67CCCA' : '#F05B7F',
      }}
      ref={alertRef}
    >
      <div className="text-center font-weight-bold mb-5">
        {alert.alertMessage && Array.isArray(alert.alertMessage)
          ? alert.alertMessage.map((error, index) => <p key={index}>{error}</p>)
          : alert.alertMessage}
      </div>
    </div>
  );
};

export default ErrorMessage;
