import './index.scss';

import React from 'react';

export const SubmitButton = ({ disabled, BtnText, classes, loading }) => {
    return (
      <button
        type="submit"
        className={`btn font-weight-bolder ${classes} px-9 py-4`}
        style={{ color: '#fff', background: '#0071CE' }}
        disabled={disabled}
      >
        {BtnText ? BtnText : 'Submit'}
        {loading && <span className="ml-3 spinner spinner-white" />}
      </button>
    );
  };