/**
 * Entry application component used to compose providers and render Routes.
 * */
 import React from 'react';
 import { Provider } from 'react-redux';
 import { BrowserRouter } from 'react-router-dom';
 import { Routes } from '../app/Routes';
 import { I18nProvider } from '../_metronic/i18n';
 import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
 import InvalidateCache from './InvalidateCache';
 
 export default function App({ store, basename }) {
   return (
     <InvalidateCache>
       {({ loading, isLatestVersion, refreshCacheAndReload }) => {
         if (loading) return null;
         if (!loading && !isLatestVersion) refreshCacheAndReload();
 
         return (
           /* Provide Redux store */
           <Provider store={store}>
             {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
             <React.Suspense fallback={<LayoutSplashScreen />}>
               {/* Override `basename` (e.g: `homepage` in `package.json`) */}
               <BrowserRouter basename={basename}>
                 {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                 <MaterialThemeProvider>
                   {/* Provide `react-intl` context synchronized with Redux state.  */}
                   <I18nProvider>
                     {/* Render routes with provided `Layout`. */}
                     <Routes />
                   </I18nProvider>
                 </MaterialThemeProvider>
               </BrowserRouter>
             </React.Suspense>
           </Provider>
         );
       }}
     </InvalidateCache>
   );
 }
 