import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';

import * as auth from '../_redux/authRedux';

import Input from '../../Common/Input/Input';
import UnableViewPassword from '../../Common/UnableViewPassword';
import { SubmitButton } from '../../Common/Button/SubmitButton';
import { login } from '../_redux/authCrud';

import '../styles/registration.scss';

const initialValues = {
  email: '',
  password: '',
};

const Login = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      try {
        const { data } = await login(
          values.email.toLowerCase(),
          values.password
        );
        disableLoading();

        const {
          accessToken,
          user: {
            partner: { id: partnerId },
          },
        } = data;
        localStorage.setItem('partnerAdminToken', accessToken);
        localStorage.setItem('partnerId', partnerId);
        props.login(data);
      } catch (error) {
        disableLoading();
        setSubmitting(false);
        setStatus(
          error.response.data
            ? error.response.data.message
            : 'Unable to login.Try again.'
        );
      }
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}

      <div className="text-center mb-5 mb-lg-10">
        <h1 className="font-size-h1">Sign In</h1>
        <p>Enter your email and password</p>
      </div>

      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 ">
            <div
              className="text-center font-weight-bold"
              style={{ color: '#F05B7F' }}
            >
              {formik.status}
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="form-group fv-plugins-icon-container">
          <Input
            placeholder="Email"
            type="email"
            className={`form-control input-field ${getInputClasses('email')}`}
            name="email"
            formik={formik}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <Input
            placeholder="Password"
            type={viewPassword ? 'text' : 'password'}
            className={`form-control input-field ${getInputClasses(
              'password'
            )}`}
            name="password"
            formik={formik}
          />
          <UnableViewPassword
            handleClickEvent={() => setViewPassword(!viewPassword)}
            viewPassword={viewPassword}
            classes="password-eye-icon"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="error-message-spacing fv-help-block">
                {formik.errors.password}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mobile-row">
          <SubmitButton
            loading={loading}
            BtnText="Sign in"
            disabled={formik.isSubmitting}
            classes="btn-mobile space-top"
          />
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default injectIntl(connect(null, { ...auth.actions })(Login));
