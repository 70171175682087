export const blocksHeaders = [
  { columnName: 'Block ID', width: '100px' },
  { columnName: 'Date Time', width: '150px' },
  { columnName: 'Total Transactions', width: '180px' },
  { columnName: 'Status', width: '100px' },
  { columnName: 'Amount Deposited', width: '180px' },
  { columnName: 'Amount Withdrawn', width: '190px' },
  { columnName: 'Transactions', width: '100px' },
];

export const transactionsHeaders = [
  { columnName: 'Transaction ID', width: '150px' },
  { columnName: 'Investment Type', width: '165px' },
  { columnName: 'Operation Type', width: '180px' },
  { columnName: 'Date Executed', width: '150px' },
  { columnName: 'Investor Identifier', width: '250px' },
  { columnName: 'Investor Name', width: '150px' },
  { columnName: 'Investor Email', width: '100px' },
  { columnName: 'KYC Status', width: '150px' },
  { columnName: 'Original Amount', width: '160px' },
];
