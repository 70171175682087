import axios from "axios";
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const LOGIN_URL = `${REACT_APP_BASE_URL}/v1/partner-admin/auth/login`;


const login = async (email, password) => {
  const res = await axios.post(LOGIN_URL, { email, password });

  return res
};

export {
  login,
};
