import React from 'react';
import { currencyHelper } from '../../../helpers/currencyHelper';

const DepositAndWithdrawAmount = ({ amounts, currency }) => {
  return (
    <div>
      <table style={{ marginBottom: '0px' }} className="table">
        {amounts?.map((amount) => (
          <tr>
            <td className="td-font-size-11">
              <span class="text-bold-blue">Amount</span>
            </td>
            <td className="td-font-size-11">
              {currencyHelper(amount?.amount, amount?.currency)}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default DepositAndWithdrawAmount;
