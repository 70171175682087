/* eslint-disable jsx-a11y/anchor-is-valid */

import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import '../styles/registration.scss';

import { Redirect, Switch } from 'react-router-dom';
import AppFooter from '../../../utils/appFooter';
import { ContentRoute } from '../../../../_metronic/layout';
import ExternaLeft from '../../Common/ExternalLeft';
import Login from './Login';
import React from 'react';
import LoginLeftContent from '../components/LoginLeftContent';

export const AuthPage = () => {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login"
          style={{ backgroundColor: '#fff' }}
        >
          {/*begin::Aside*/}
          <ExternaLeft>{<LoginLeftContent />}</ExternaLeft>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}

            <div className="d-flex flex-column-fluid flex-center mt-5 mt-lg-15">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none justify-content-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                <div className="font-weight-bold text-center">
                  <AppFooter />
                </div>
              </div>
              {/* remove div for links */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};
